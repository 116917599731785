
.navigation {
    transition: all 1s;
    padding: 30px 0px;
}
.navigation.scrolled {
    background-color: #00416ac5;
    padding: 10px;
}
.navigation.scrolled img {
    height: 50px;
    transition: all 1s;
}
.jolo{
    transition: all 1s;
    background-color: #00416a;
}
.jolo2{
    transition: all 1s;
    
}