

.listText{
    color: white;
    font-family: 'Kanit';
    font-weight: bold;
}
.descriptionText{
  font-family: 'Kanit';
}
