@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body,
#root {
  scroll-behavior: smooth;
  scroll-snap-type: y proximity;
  scroll-snap-destination: 0% 100%;
}

body {
  background-color: #00416a;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 10px; 
  z-index: 10000;
}

body::-webkit-scrollbar-thumb {
  background-color: #ffffff3b;
  border-radius: 5px; 
}