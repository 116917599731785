.mockupTitle {
    color: white;
    background-clip: text;
    -webkit-background-clip: text;
    font-family: 'Kanit';
    font-weight: bold;
    line-height: 65px;

}

.mainButton {
    padding: 1em 2em;
    border: none;
    border-radius: 15px;
    font-weight: bold;
    letter-spacing: 5px;
    text-transform: uppercase;
    cursor: pointer;
    color: #00416A;
    transition: all 1000ms;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    outline: 2px solid #071952;
    background-image: linear-gradient(135deg, #f5f7fa 10%, #c3cfe2 100%);
}

.mainButton:hover {
    color: #ffffff;
    transform: scale(1.1);
    outline: 2px solid #ffffff;
    box-shadow: 4px 5px 17px -4px #268391;
}

.mainButton::before {
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
    width: 0;
    height: 100%;
    background-image: linear-gradient(135deg, #00416A 10%, #E4E5E6 100%);
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
}

.mainButton:hover::before {
    width: 250%;
}

.text-container {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.5s, transform 0.5s;
  }
  
  .text-container.visible {
    opacity: 1;
    transform: translateX(0);
  }
