.flip-card {
    background-color: transparent;
    width: 350px;
    height: 450px;
    perspective: 1000px;
    font-family: sans-serif;
}

.mainTitle{
    color: white;
    font-family: 'Kanit';
    font-weight: bold;
}

.flipTitle {
    color: white;
    font-family: 'Kanit';
    font-weight: bold;
}

.flip-card-inner {
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 1rem;
}

.flip-card-front {
    background: #00416a8e;
    color: rgb(255, 255, 255);
}

.flip-card-back {
    background: #00416a;
    color: white;
    transform: rotateY(180deg);
}



//----------------------------------------------------------------------------1 model---------------------------------------------------------------------------------//

.boxes {
    margin-top: -120px;
    margin-bottom: 130px;
    margin-left: 120px;
    --size: 50px;
    --duration: 1s;
 
    transform-style: preserve-3d;
    transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
}

.boxes .box {
    width: var(--size);
    height: var(--size);
    position: absolute;
    transform-style: preserve-3d;
}

.boxes .box:nth-child(1) {
    transform: translate(100%, 0);
    -webkit-animation: box1 var(--duration) linear infinite;
    animation: box1 var(--duration) linear infinite;
}

.boxes .box:nth-child(2) {
    transform: translate(0, 100%);
    -webkit-animation: box2 var(--duration) linear infinite;
    animation: box2 var(--duration) linear infinite;
}

.boxes .box:nth-child(3) {
    transform: translate(100%, 100%);
    -webkit-animation: box3 var(--duration) linear infinite;
    animation: box3 var(--duration) linear infinite;
}

.boxes .box:nth-child(4) {
    transform: translate(200%, 0);
    -webkit-animation: box4 var(--duration) linear infinite;
    animation: box4 var(--duration) linear infinite;
}

.boxes .box>div {
    --background: #00416a8e;
    --top: auto;
    --right: auto;
    --bottom: auto;
    --left: auto;
    --translateZ: calc(var(--size) / 2);
    --rotateY: 0deg;
    --rotateX: 0deg;
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--background);
    top: var(--top);
    right: var(--right);
    bottom: var(--bottom);
    left: var(--left);
    transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));

}

.boxes .box>div:nth-child(1) {
    --top: 0;
    --left: 0;
    border: 2px solid white;
}

.boxes .box>div:nth-child(2) {
    --background: #00416a8e;
    border: 2px solid white;
    --right: 0;
    --rotateY: 90deg;
}

.boxes .box>div:nth-child(3) {
    --background: #00416a8e;
    border: 2px solid white;
    --rotateX: -90deg;
}

@-webkit-keyframes box1 {

    0%,
    50% {
        transform: translate(100%, 0);
    }

    100% {
        transform: translate(200%, 0);
    }
}

@keyframes box1 {

    0%,
    50% {
        transform: translate(100%, 0);
    }

    100% {
        transform: translate(200%, 0);
    }
}

@-webkit-keyframes box2 {
    0% {
        transform: translate(0, 100%);
    }

    50% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(100%, 0);
    }
}

@keyframes box2 {
    0% {
        transform: translate(0, 100%);
    }

    50% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(100%, 0);
    }
}

@-webkit-keyframes box3 {

    0%,
    50% {
        transform: translate(100%, 100%);
    }

    100% {
        transform: translate(0, 100%);
    }
}

@keyframes box3 {

    0%,
    50% {
        transform: translate(100%, 100%);
    }

    100% {
        transform: translate(0, 100%);
    }
}

@-webkit-keyframes box4 {
    0% {
        transform: translate(200%, 0);
    }

    50% {
        transform: translate(200%, 100%);
    }

    100% {
        transform: translate(100%, 100%);
    }
}

@keyframes box4 {
    0% {
        transform: translate(200%, 0);
    }

    50% {
        transform: translate(200%, 100%);
    }

    100% {
        transform: translate(100%, 100%);
    }
}

//--------------------------------------------------------------------------------- 2 model -------------------------------------------------------------------//

.loader {
    position: absolute;
    top: 3%;
    left: 50%;
    transform: translate(-50%, -50%);
    perspective: 800px;
}

.loader-cube {
    width: 100px;
    height: 100px;
    position: relative;
    transform-style: preserve-3d;
    transform-origin: 50% 50%;
    animation: rotate 10s infinite linear;
}

.loader-cube:before,
.loader-cube:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.loader-cube:before {
    transform: rotateX(90deg) translateZ(50px);
}

.loader-cube:after {
    transform: rotateY(90deg) translateZ(50px);
}

.loader-cube .face {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(252, 248, 248);
    opacity: 0.8;
    border: 2px solid rgb(252, 250, 250);
}

.loader-cube .face:nth-child(1) {
    transform: rotateY(0deg) translateZ(50px);
    background-color: #00416a8e;
}

.loader-cube .face:nth-child(2) {
    transform: rotateX(90deg) translateZ(50px);
    background-color: #00416a8e;
}

.loader-cube .face:nth-child(3) {
    transform: rotateY(180deg) translateZ(50px);
    background-color: #00416a8e;
}

.loader-cube .face:nth-child(4) {
    transform: rotateX(-90deg) translateZ(50px);
    background-color: #00416a8e;
}

.loader-cube .face:nth-child(5) {
    transform: rotateY(90deg) translateZ(50px);
    background-color: #00416a8e;
}

.loader-cube .face:nth-child(6) {
    transform: rotateY(0deg) translateZ(-50px);
    background-color: #00416a8e;
}

@keyframes rotate {
    0% {
        transform: rotateY(0deg) rotateX(0deg);
    }

    100% {
        transform: rotateY(1turn) rotateX(1turn);
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    display: none;
    z-index: 9999;
}

//------------------------------------------------------------------------------------- 3 model ----------------------------------------------------------------//

.loaderr {
    scale: 4;
    position: absolute;
    top: -15%;
    left: 40%;
}

.boxer {
    position: relative;
    opacity: 0;
}

.side-left {
    position: absolute;
    background-color: #00416a8e;
    border: 0.5px solid rgb(252, 250, 250);
    width: 19px;
    height: 5px;
    transform: skew(0deg, -25deg);
    top: 14px;
    left: 10px;
}

.side-right {
    position: absolute;
    background-color: #00416a8e;
    border: 0.5px solid rgb(252, 250, 250);
    width: 19px;
    height: 5px;
    transform: skew(0deg, 25deg);
    top: 14px;
    left: -9px;
}

.side-top {
    position: absolute;
    background-color: #00416a8e;
    border: 0.5px solid rgb(252, 250, 250);
    width: 20px;
    height: 20px;
    rotate: 45deg;
    transform: skew(-20deg, -20deg);
}

.box-1 {
    animation: from-left 4s infinite;
}

.box-2 {
    animation: from-right 4s infinite;
    animation-delay: 1s;
}

.box-3 {
    animation: from-left 4s infinite;
    animation-delay: 2s;
}

.box-4 {
    animation: from-right 4s infinite;
    animation-delay: 3s;
}

@keyframes from-left {
    0% {
        z-index: 20;
        opacity: 0;
        translate: -20px -6px;
    }

    20% {
        z-index: 10;
        opacity: 1;
        translate: 0px 0px;
    }

    40% {
        z-index: 9;
        translate: 0px 4px;
    }

    60% {
        z-index: 8;
        translate: 0px 8px;
    }

    80% {
        z-index: 7;
        opacity: 1;
        translate: 0px 12px;
    }

    100% {
        z-index: 5;
        translate: 0px 30px;
        opacity: 0;
    }
}

@keyframes from-right {
    0% {
        z-index: 20;
        opacity: 0;
        translate: 20px -6px;
    }

    20% {
        z-index: 10;
        opacity: 1;
        translate: 0px 0px;
    }

    40% {
        z-index: 9;
        translate: 0px 4px;
    }

    60% {
        z-index: 8;
        translate: 0px 8px;
    }

    80% {
        z-index: 7;
        opacity: 1;
        translate: 0px 12px;
    }

    100% {
        z-index: 5;
        translate: 0px 30px;
        opacity: 0;
    }
}


//----------------------------------------------------------------------------Learn More Button--------------------------------------------------------------------//
