.aboutLoader {
    width: 400px;
    height: 400px;
    border-left: 25px solid #0f659b;
    border-radius: 50%;
    animation: right5231 5s linear infinite;
  }
  
  .aboutLoader::before,
    .aboutLoader::after {
    content: "";
    width: 300px;
    height: 300px;
    display: block;
    position: absolute;
    top: calc(50% - 150px);
    left: calc(50% - 150px);
    border-right: 25px solid #ffffff;
    border-radius: 50%;
    animation: left036 5s linear infinite;
  }
  
  .aboutLoader::after {
    width: 150px;
    height: 150px;
    top: calc(50% - 70px);
    left: calc(50% - 70px);
    border: 0;
    border-top: 25px solid #0f659b;
    animation: none;
  }
  @media (max-width: 768px) {
    .aboutLoader {
        width: 250px;
        height: 250px;
        border-left: 15px solid #0f659b;
        border-radius: 50%;
        animation: right5231 5s linear infinite;
      }
      
      .aboutLoader::before,
        .aboutLoader::after {
        content: "";
        width: 150px;
        height: 150px;
        display: block;
        position: absolute;
        top: calc(50% - 75px);
        left: calc(50% - 75px);
        border-right: 15px solid #ffffff;
        border-radius: 50%;
        animation: left036 5s linear infinite;
      }
      
      .aboutLoader::after {
        width: 50px;
        height: 50px;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
        border: 0;
        border-top: 15px solid #0f659b;
        animation: none;
      }
  }
  
  @keyframes right5231 {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes left036 {
    from {
      transform: rotate(720deg);
    }
  
    to {
      transform: rotate(0deg);
    }
  }