.mainContainer {}

.canva {
    width: 100%;
    height: 100%;
}

.container {
    position: relative;
}

.fade-in-content {
    opacity: 0;
    transition: opacity 2s;
}
.fade-out-content{
    transition: opacity 2s;
}
.fade-out-content.show {
    opacity: 1;
}

.fade-in-content.show {
    opacity: 1;
}