.servicesText {
    color: white;
    font-family: 'Kanit';
}

#serviceRing {
    width: 500px;
    height: 500px;
    border: 1px solid transparent;
    border-radius: 50%;
    position: absolute;
}

@media (max-width: 768px) {
    #serviceRing {
        width: 250px;
        height: 250px;
        border: 1px solid transparent;
        border-radius: 50%;
        position: absolute;
    }
}

#serviceRing:nth-child(1) {
    border-bottom: 20px solid #0f659b;
    animation: rotate1 5s linear infinite;
}

@keyframes rotate1 {
    from {
        transform: rotateX(50deg) rotateZ(110deg);
    }

    to {
        transform: rotateX(50deg) rotateZ(470deg);
    }
}

#serviceRing:nth-child(2) {
    border-bottom: 20px solid #ffffff;
    animation: rotate2 5s linear infinite;
}

@keyframes rotate2 {
    from {
        transform: rotateX(20deg) rotateY(50deg) rotateZ(20deg);
    }

    to {
        transform: rotateX(20deg) rotateY(50deg) rotateZ(380deg);
    }
}

#serviceRing:nth-child(3) {
    border-bottom: 20px solid #0f659b;
    animation: rotate3 5s linear infinite;
}

@keyframes rotate3 {
    from {
        transform: rotateX(40deg) rotateY(130deg) rotateZ(450deg);
    }

    to {
        transform: rotateX(40deg) rotateY(130deg) rotateZ(90deg);
    }
}

#serviceRing:nth-child(4) {
    border-bottom: 20px solid rgb(255, 255, 255);
    animation: rotate4 5s linear infinite;
}

@keyframes rotate4 {
    from {
        transform: rotateX(70deg) rotateZ(270deg);
    }

    to {
        transform: rotateX(70deg) rotateZ(630deg);
    }
}